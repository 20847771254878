import '@fontsource/raleway/400.css'
import '@fontsource/open-sans/700.css'

import React from 'react';
import '../styles/globals.css'
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import 'firebase/auth';
import { ChakraProvider } from '@chakra-ui/react'
import {
  extendTheme
} from '@chakra-ui/react';


// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.NEXT_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);



const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
};

const fonts = {
  heading: `'Open Sans', sans-serif`,
  body: `'Raleway', sans-serif`,
}

const styles = {
  global: () => ({
    body: {
      bg: 'gray-200',
    },
  })
};

const theme = extendTheme({ colors, fonts, styles })


function MyApp({ Component, pageProps }) {
  return (
    <ChakraProvider theme={theme}>
      <Component {...pageProps} />
    </ChakraProvider>
  )
}

export default MyApp
